@import 'colorbox/colorbox.min.css';
@import '//katyasivers.com/core/view/ui/css/hot-sneaks/jquery-ui.css';
html {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  background: #FFFFFF;
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
}
* {
  padding: 0;
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}
summary {
  display: list-item;
}
audio,
button,
canvas,
img,
input,
select,
svg,
textarea,
video {
  vertical-align: middle;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
img {
  border-style: none;
  -ms-interpolation-mode: bicubic;
}
progress {
  vertical-align: baseline;
}
svg:not(:root) {
  overflow: hidden;
}
a {
  background: transparent;
  color: #000000;
  text-decoration: none;
  -webkit-text-decoration-skip: objects;
  transition: color 0.3s, border 0.3s, background-color 0.3s;
}
a:active,
a:focus,
a:hover,
input:focus,
textarea:focus,
select:focus,
button:focus {
  outline-width: 0;
}
::-moz-selection {
  background: #B3D4FC;
  text-shadow: none;
}
::selection {
  background: #B3D4FC;
  text-shadow: none;
}
h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  text-decoration: none;
}
h1,
.h1 {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 0.45454545rem 0rem;
  font: 34px/120% Arial, Helvetica, sans-serif;
  font-size: 34px;
  line-height: 120%;
  text-transform: none;
}
h1,
.h1,
h1 a,
.h1 a {
  color: #000000;
}
h2,
.h2 {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 26px/120% Arial, Helvetica, sans-serif;
  font-size: 26px;
  line-height: 120%;
  text-transform: none;
}
h2,
.h2,
h2 a,
.h2 a {
  color: #000000;
}
h3,
.h3 {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 25px/120% Arial, Helvetica, sans-serif;
  font-size: 25px;
  line-height: 120%;
  text-transform: none;
}
h3,
.h3,
h3 a,
.h3 a {
  color: #000000;
}
h4,
.h4 {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 24px/120% Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 120%;
  text-transform: none;
}
h4,
.h4,
h4 a,
.h4 a {
  color: #000000;
}
h5,
.h5 {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 23px/120% Arial, Helvetica, sans-serif;
  font-size: 23px;
  line-height: 120%;
  text-transform: none;
}
h5,
.h5,
h5 a,
.h5 a {
  color: #000000;
}
h6,
.h6 {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  text-transform: none;
}
h6,
.h6,
h6 a,
.h6 a {
  color: #000000;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  /* autoprefixer: off */
  text-decoration: underline dotted;
}
code,
kbd,
pre,
samp {
  font-family: Courier, 'Courier New', monospace;
  font-size: 1em;
}
dfn {
  font-style: italic;
}
hr {
  /* autoprefixer: off */
  box-sizing: content-box;
  border: 0;
  height: 0;
  overflow: visible;
}
legend {
  /* autoprefixer: off */
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  color: inherit;
  white-space: normal;
}
p {
  padding: 0rem 0rem 2.27272727rem 0rem;
  line-height: 120%;
}
pre {
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0px;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
ol li {
  list-style: decimal outside;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
[type="checkbox"],
[type="radio"] {
  /* autoprefixer: off */
  box-sizing: border-box;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.input,
select,
textarea {
  box-sizing: border-box;
  background: #FFFFFF;
  border: 1px solid #7D7D7D;
  box-shadow: none;
  border-radius: 0rem;
  height: 1.36363636rem;
  padding-left: 0.22727273rem;
  padding-right: 0.22727273rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  color: #7D7D7D;
  text-shadow: none;
  transition: border .3s, background .3s;
}
.input:-moz-placeholder,
select:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  color: #CCCCCC;
}
.input::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  color: #CCCCCC;
}
.input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  color: #CCCCCC;
}
.input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  color: #CCCCCC;
}
.input:focus,
select:focus,
textarea:focus {
  border-color: #7D7D7D !important;
  color: #7D7D7D !important;
  background-color: #ffffff !important;
}
.input[readonly],
select[readonly],
textarea[readonly],
.input[disabled],
select[disabled],
textarea[disabled] {
  border-color: #999999 !important;
  color: #999999 !important;
  background-color: #ffffff !important;
}
textarea {
  height: auto;
  padding: 0.22727273rem;
  line-height: 120%;
  overflow: auto;
  resize: vertical;
}
textarea[readonly],
textarea[disabled] {
  overflow: auto;
  resize: none;
}
select {
  padding-right: 0;
}
select optgroup {
  font-weight: bold;
}
select option {
  padding: 0rem 0.22727273rem;
}
label {
  cursor: pointer;
}
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    box-shadow: none !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #000000 !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  blockquote,
  img,
  pre,
  tr {
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p {
    orphans: 3;
    widows: 3;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    page-break-after: avoid;
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 1px solid #999999;
  }
  .input,
  select,
  textarea {
    border-color: #000000;
  }
  aside,
  footer,
  header,
  nav,
  #awst-panel-wrapper,
  .breadcrumbs,
  .pagination,
  .noprint {
    display: none !important;
  }
  main {
    float: none !important;
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
header {
  box-sizing: border-box;
  background: none;
  height: auto;
  padding: 0rem;
}
#structure {
  position: relative;
  background: none;
  margin: 0 auto;
}
#content {
  background: none;
  padding-bottom: 0rem;
}
#content .wrapper:before,
#content .wrapper:after {
  content: " ";
  display: table;
}
#content .wrapper:after {
  clear: both;
}
aside,
main {
  box-sizing: border-box;
  float: left;
}
aside#left {
  background: none;
  width: 0rem;
  padding: 0rem;
}
aside#right {
  background: none;
  width: 0rem;
  padding: 0rem;
}
main {
  background: none;
  width: calc(100% - 0px - 0px);
  padding: 0rem;
}
main.no-aside-left {
  width: calc(100% - 0px);
  padding-left: 0;
}
main.no-aside-right {
  width: calc(100% - 0px);
  padding-right: 0;
}
main.full,
main.no-aside-left.no-aside-right {
  float: none;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
footer {
  position: relative;
  box-sizing: border-box;
  background: #FFFFFF;
  padding: 0rem;
  margin: 0rem auto;
  text-align: left;
}
.wrapper {
  box-sizing: border-box;
  padding: 0rem 0.45454545rem;
  margin: 0rem auto;
  width: 43.36363636rem;
}
.breadcrumbs {
  margin: 0rem 0rem 2.27272727rem 0rem;
  line-height: 0;
  letter-spacing: -1px;
}
.breadcrumbs a,
.breadcrumbs span {
  display: inline-block;
  padding: 0rem 0.22727273rem 0rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  letter-spacing: normal;
  text-decoration: underline;
}
.breadcrumbs a:hover {
  text-decoration: none;
}
.breadcrumbs span {
  padding: 0rem 0.22727273rem 0rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-decoration: none !important;
}
.breadcrumbs span.breadcrumbs-separator {
  padding: 0rem 0.22727273rem 0rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
}
.breadcrumbs img {
  vertical-align: baseline;
}
.pagination {
  margin: 0rem 0rem 2.27272727rem 0rem;
}
.pagination ul {
  overflow: hidden;
}
.pagination ul > li {
  float: left;
  padding: 0rem 0.22727273rem;
  list-style: none;
}
.pagination ul > li * {
  display: block;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-decoration: underline;
}
.pagination ul > li *:hover {
  text-decoration: none;
}
.pagination ul > li.page-selected {
  padding: 0rem 0.22727273rem;
}
.pagination ul > li.page-selected * {
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-decoration: none !important;
}
.pagination ul > li.page-backward,
.pagination ul > li.page-forward {
  padding: 0rem 0.22727273rem;
}
.pagination ul > li.page-backward *,
.pagination ul > li.page-forward * {
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
}
.form {
  box-sizing: border-box;
  background: none;
  border: 0;
  box-shadow: none;
  border-radius: 0rem;
  width: 100%;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
}
.form .form-header {
  display: inline-block;
  background: none;
  border: 0;
  border-radius: 0rem;
  padding: 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-align: left;
}
.form .form-body {
  background: none;
  border: 0;
  border-radius: 0rem;
  padding: 0rem;
}
.form .form-title {
  display: block;
  float: left;
  width: 27%;
  padding: 0.13636364rem 3% 0rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-transform: none;
  text-align: left;
}
.form .form-field {
  float: left;
  width: 70%;
  padding: 0rem;
}
.form .input,
.form select,
.form textarea {
  width: 100%;
}
.form .form-error .input,
.form .form-error select,
.form .form-error textarea {
  background: url(../images/stop-round.png) no-repeat 5px 50% #ff9595;
  border-color: #950000;
  padding-left: 29px;
  padding-right: 0.22727273rem;
  color: #950000;
}
.form .form-error .input:-moz-placeholder,
.form .form-error select:-moz-placeholder,
.form .form-error textarea:-moz-placeholder {
  opacity: 1;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  color: #950000;
}
.form .form-error .input::-moz-placeholder,
.form .form-error select::-moz-placeholder,
.form .form-error textarea::-moz-placeholder {
  opacity: 1;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  color: #950000;
}
.form .form-error .input:-ms-input-placeholder,
.form .form-error select:-ms-input-placeholder,
.form .form-error textarea:-ms-input-placeholder {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  color: #950000;
}
.form .form-error .input::-webkit-input-placeholder,
.form .form-error select::-webkit-input-placeholder,
.form .form-error textarea::-webkit-input-placeholder {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  color: #950000;
}
.form .form-success .input,
.form .form-success select,
.form .form-success textarea {
  background: url(../images/accept-green.png) no-repeat 5px 50% #d8f6aa;
  border-color: #5E9010;
  padding-left: 29px;
  padding-right: 0.22727273rem;
  color: #5E9010;
}
.form .form-success .input:focus,
.form .form-success select:focus,
.form .form-success textarea:focus {
  background-color: #e2f8c0;
}
.form .form-error select,
.form .form-success select {
  padding-left: 24px;
}
.form .form-error textarea,
.form .form-success textarea {
  background-position: 5px 6px;
}
.form span.form-required,
.form .form-required span {
  font: bold 22px/120% Arial, Helvetica, sans-serif;
  color: #950000;
}
.form-size-full {
  width: 100% !important;
}
.form-size-three-quarter {
  width: 75% !important;
}
.form-size-two-thirds {
  width: 66% !important;
}
.form-size-half {
  width: 48% !important;
}
.form-size-third {
  width: 32% !important;
}
.form-size-quarter {
  width: 24% !important;
}
.form-size-auto {
  width: auto !important;
}
.form-separator {
  display: inline-block;
  width: 4%;
  text-align: center;
}
.form-hint {
  padding: 0.45454545rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
}
.form-submit {
  padding: 0rem;
  text-align: center;
}
.form-error-message,
.form-success-message {
  box-sizing: border-box;
  position: relative;
  background-position: 5px 50% !important;
  box-shadow: none;
  border-radius: 0rem;
  padding: 5px 5px 5px 29px;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
}
.form-field .form-error-message,
.form-field .form-success-message {
  top: -0.04545455rem;
  margin: 0rem;
}
.form-error-message {
  background: url(../images/stop-round.png) no-repeat 5px 50% #ff9595;
  border: 1px solid #950000;
  color: #950000;
}
.form-success-message {
  background: none;
  border: 0;
  color: #5E9010;
}
.list,
.list-hr {
  margin: 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
}
.list > li,
.list-hr > li {
  margin: 0rem 0rem 2.27272727rem 0rem;
  list-style: none;
}
.list > li.list-element-last,
.list-hr > li.list-element-last {
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}
.list > li p:last-child,
.list-hr > li p:last-child {
  padding: 0;
}
.list > li ul:last-child,
.list-hr > li ul:last-child,
.list > li ol:last-child,
.list-hr > li ol:last-child {
  margin-bottom: 0;
}
.list > li ul:last-child li:last-child,
.list-hr > li ul:last-child li:last-child,
.list > li ol:last-child li:last-child,
.list-hr > li ol:last-child li:last-child {
  margin-bottom: 0;
}
.list-hr > li {
  padding: 0rem 0rem 2.27272727rem 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  border-bottom: 1px solid #7D7D7D;
}
.list-hr > li.list-element-last {
  border: 0;
}
table {
  width: 100%;
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
}
table th,
table td {
  padding: 0rem 0.45454545rem 0.45454545rem 0.45454545rem;
}
table th p:last-child,
table td p:last-child {
  padding: 0;
}
table th {
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-align: left;
}
.table-bordered {
  border-top: 1px solid #7D7D7D;
  border-left: 1px solid #7D7D7D;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
}
.table-bordered th,
.table-bordered td {
  border-right: 1px solid #7D7D7D;
  border-bottom: 1px solid #7D7D7D;
  padding: 0.45454545rem;
}
.table-bordered th,
.table-bordered thead td {
  background: #7D7D7D;
  padding: 0.45454545rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #FFFFFF;
  text-align: left;
}
.table-bordered td {
  background: #FFFFFF;
}
.table-striped tbody tr:nth-child(even) > td {
  background: #CCCCCC;
}
.popup {
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  background: #FFFFFF;
  border: 0;
  box-shadow: none;
  border-radius: 0rem;
  width: 300px;
  height: auto;
  text-align: left;
  white-space: normal;
}
.popup .popup-header,
.popup .popup-body,
.popup .popup-footer {
  box-sizing: border-box;
  width: 100%;
}
.popup .popup-header {
  background: none;
  border: 0;
  border-radius: 0rem;
  height: 70px;
  padding: 0.90909091rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-align: center;
}
.popup .popup-body {
  background: none;
  border: 0;
  border-radius: 0rem;
  height: auto;
  padding: 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-align: left;
}
.popup .popup-footer {
  background: none;
  border: 0;
  border-radius: 0rem;
  height: 70px;
  padding: 0.90909091rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-align: center;
}
.popup .popup-close {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: url(../images/button-close.png) no-repeat 0 0;
  width: 35px;
  height: 35px;
  padding: 0rem;
  font: 35px/100% Arial, Helvetica, sans-serif;
  font-size: 35px;
  line-height: 100%;
  color: #000000;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
}
.fixer #content {
  position: fixed;
  width: 100%;
}
header,
#header-fake {
  padding: 0.22727273rem 0rem 2.27272727rem 0rem;
}
header .text-container,
#header-fake .text-container {
  margin: 0;
}
.fixer header {
  position: fixed;
  width: 100%;
  top: 0;
}
#header-fake {
  visibility: hidden;
}
nav {
  padding: 0rem 0rem 2.27272727rem 0rem;
}
nav.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}
nav li {
  list-style: none;
}
nav li:not(:first-child) {
  display: inline-block;
}
nav li:last-of-type:not(:only-of-type) {
  float: right;
}
nav li a {
  border-bottom: 1px solid transparent;
  font-size: 34px;
  line-height: 120%;
}
nav li a:not(.no-separator):after {
  content: ',';
}
nav li a:hover {
  border-color: #000000;
}
#projects {
  margin-top: 0.45454545rem;
}
#projects .text-container,
#ks-drag-container .text-container,
#projects .text-container h1,
#ks-drag-container .text-container h1,
#projects .text-container .h1,
#ks-drag-container .text-container .h1 {
  margin: 0;
}
#projects .text-container p,
#publications .text-container p,
#media .text-container p,
#contacts .text-container p,
#ks-drag-container .text-container p,
.error-page .text-container p {
  padding-bottom: 0.90909091rem;
}
#projects .text-container p:last-child,
#publications .text-container p:last-child,
#media .text-container p:last-child,
#contacts .text-container p:last-child,
#ks-drag-container .text-container p:last-child,
.error-page .text-container p:last-child {
  padding: 0;
}
#projects .text-container a,
#publications .text-container a,
#media .text-container a,
#contacts .text-container a,
#ks-drag-container .text-container a,
.error-page .text-container a {
  border-bottom: 1px solid;
}
#projects .text-container a:hover,
#publications .text-container a:hover,
#media .text-container a:hover,
#contacts .text-container a:hover,
#ks-drag-container .text-container a:hover,
.error-page .text-container a:hover {
  border-color: transparent;
}
#publications .text-container,
#media .text-container,
#contacts .text-container,
.error-page .text-container {
  font-size: 16px;
  line-height: 120%;
}
.block {
  position: relative;
  margin: 0.90909091rem 0rem 2.27272727rem 0rem;
}
.block .abs {
  position: absolute;
}
.isDesktop .block .fly,
.isDesktop .block .fly * {
  cursor: move;
}
.curators-text {
  background: #FFFFFF;
  padding: 1.59090909rem 1.13636364rem 0.68181818rem 1.59090909rem;
}
.more-handler {
  display: inline-block;
  border-bottom-style: dotted !important;
}
.more-content {
  display: none;
  margin-top: 0.22727273rem;
  font-size: 16px;
  line-height: 120%;
}
.footer-counters {
  float: left;
  padding: 0rem 4.09090909rem 0rem 0.90909091rem;
}
.footer-counters img {
  float: left;
  margin-right: 0.45454545rem;
}
.articles-list {
  margin: 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
}
.articles-list > li {
  margin: 0rem 0rem 2.27272727rem 0rem;
  list-style: none;
}
.articles-list > li.list-element-last {
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}
.articles-list > li p:last-child {
  padding: 0;
}
.articles-list > li ul:last-child,
.articles-list > li ol:last-child {
  margin-bottom: 0;
}
.articles-list > li ul:last-child li:last-child,
.articles-list > li ol:last-child li:last-child {
  margin-bottom: 0;
}
.articles-list > li {
  padding: 0rem 0rem 2.27272727rem 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  border-bottom: 1px solid #7D7D7D;
}
.articles-list > li.list-element-last {
  border: 0;
}
.articles-list .articles-title {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 24px/120% Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 120%;
  text-transform: none;
}
.articles-list .articles-title a {
  text-decoration: none;
}
.articles-list .articles-title,
.articles-list .articles-title a {
  color: #000000;
}
.contacts-title {
  margin-bottom: 0.22727273rem;
  font-size: 22px;
  line-height: 120%;
  font-weight: bold;
}
.contacts-value {
  padding-left: 0.45454545rem;
  font-size: 22px;
  line-height: 120%;
}
.contacts-value.contacts-several-values > *:not(p) {
  padding: 0rem 0rem 2.27272727rem 0rem;
}
.contacts-value.contacts-several-values > *:not(:last-child) {
  padding-bottom: 0.22727273rem;
}
.contacts-map {
  float: right;
  margin: 0rem 0rem 0.45454545rem 0.68181818rem;
  width: 22.72727273rem;
  height: 18.18181818rem;
}
table.contacts-map,
th.contacts-map,
td.contacts-map {
  float: none;
  margin: 0;
  text-align: right !important;
}
.contacts-map > * {
  display: block;
  box-sizing: border-box;
  border: 0.04545455rem solid #7D7D7D;
  padding: 0.09090909rem;
}
.contacts-map > *,
.contacts-map > * img {
  width: 100% !important;
  height: 100% !important;
}
.galleries-list {
  margin: 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
}
.galleries-list > li {
  margin: 0rem 0rem 2.27272727rem 0rem;
  list-style: none;
}
.galleries-list > li.list-element-last {
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}
.galleries-list > li p:last-child {
  padding: 0;
}
.galleries-list > li ul:last-child,
.galleries-list > li ol:last-child {
  margin-bottom: 0;
}
.galleries-list > li ul:last-child li:last-child,
.galleries-list > li ol:last-child li:last-child {
  margin-bottom: 0;
}
.galleries-list > li {
  padding: 0rem 0rem 2.27272727rem 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  border-bottom: 1px solid #7D7D7D;
}
.galleries-list > li.list-element-last {
  border: 0;
}
.galleries-list .galleries-title-container:before,
.galleries-list .galleries-title-container:after {
  content: " ";
  display: table;
}
.galleries-list .galleries-title-container:after {
  clear: both;
}
.galleries-list .galleries-title-container .galleries-title {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 24px/120% Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 120%;
  text-transform: none;
}
.galleries-list .galleries-title-container .galleries-title a {
  text-decoration: none;
}
.galleries-list .galleries-title-container .galleries-title,
.galleries-list .galleries-title-container .galleries-title a {
  color: #000000;
}
.galleries-list .galleries-title-container .galleries-all {
  float: right;
  margin: 0rem 0rem 0.45454545rem 0.68181818rem;
}
table.galleries-list .galleries-title-container .galleries-all,
th.galleries-list .galleries-title-container .galleries-all,
td.galleries-list .galleries-title-container .galleries-all {
  float: none;
  margin: 0;
  text-align: right !important;
}
.galleries-foto {
  margin: 0rem 0rem -0.36363636rem -0.36363636rem;
}
.galleries-foto:before,
.galleries-foto:after {
  content: " ";
  display: table;
}
.galleries-foto:after {
  clear: both;
}
.galleries-foto .galleries-foto-item {
  float: left;
  margin: 0rem 0rem 0.36363636rem 0.36363636rem;
}
.galleries-foto .galleries-foto-item img {
  border: 0.04545455rem solid #7D7D7D;
  padding: 0.09090909rem;
}
.info-list {
  margin: 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  border-top: 1px solid #7D7D7D;
  padding-top: 0.90909091rem;
}
.info-list > li {
  margin: 0rem 0rem 2.27272727rem 0rem;
  list-style: none;
}
.info-list > li.list-element-last {
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}
.info-list > li p:last-child {
  padding: 0;
}
.info-list > li ul:last-child,
.info-list > li ol:last-child {
  margin-bottom: 0;
}
.info-list > li ul:last-child li:last-child,
.info-list > li ol:last-child li:last-child {
  margin-bottom: 0;
}
.info-list > li {
  padding: 0rem 0rem 2.27272727rem 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  border-bottom: 1px solid #7D7D7D;
}
.info-list > li.list-element-last {
  border: 0;
}
.info-list .info-title {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 24px/120% Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 120%;
  text-transform: none;
}
.info-list .info-title a {
  text-decoration: none;
}
.info-list .info-title,
.info-list .info-title a {
  color: #000000;
}
.info-list .info-announce {
  margin: 0rem 0rem 2.27272727rem 0rem;
  font-size: 22px;
  line-height: 120%;
}
.info-list .info-announce p {
  line-height: inherit;
}
.info-list .info-announce p:last-child {
  padding-bottom: 0;
}
.info-list .info-announce ul {
  margin: 0rem 0rem 2.27272727rem 0rem;
}
.info-list .info-announce ul > li {
  padding: 0rem;
  margin: 0rem 0rem 0.45454545rem 0.45454545rem;
  list-style: circle inside;
}
.info-list .info-announce ul:last-child,
.info-list .info-announce ol:last-child {
  margin-bottom: 0;
}
.info-list .info-announce ul:last-child li:last-child,
.info-list .info-announce ol:last-child li:last-child {
  margin-bottom: 0;
}
.info-list .info-announce:before,
.info-list .info-announce:after {
  content: " ";
  display: table;
}
.info-list .info-announce:after {
  clear: both;
}
.news-date {
  margin-bottom: 0.45454545rem;
  color: #808080;
}
.news-list {
  margin: 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
}
.news-list > li {
  margin: 0rem 0rem 2.27272727rem 0rem;
  list-style: none;
}
.news-list > li.list-element-last {
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}
.news-list > li p:last-child {
  padding: 0;
}
.news-list > li ul:last-child,
.news-list > li ol:last-child {
  margin-bottom: 0;
}
.news-list > li ul:last-child li:last-child,
.news-list > li ol:last-child li:last-child {
  margin-bottom: 0;
}
.news-list > li {
  padding: 0rem 0rem 2.27272727rem 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  border-bottom: 1px solid #7D7D7D;
}
.news-list > li.list-element-last {
  border: 0;
}
.news-list .news-title {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 24px/120% Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 120%;
  text-transform: none;
}
.news-list .news-title a {
  text-decoration: none;
}
.news-list .news-title,
.news-list .news-title a {
  color: #000000;
}
.news-list .news-announce {
  margin: 0rem 0rem 2.27272727rem 0rem;
  font-size: 22px;
  line-height: 120%;
}
.news-list .news-announce p {
  line-height: inherit;
}
.news-list .news-announce p:last-child {
  padding-bottom: 0;
}
.news-list .news-announce ul {
  margin: 0rem 0rem 2.27272727rem 0rem;
}
.news-list .news-announce ul > li {
  padding: 0rem;
  margin: 0rem 0rem 0.45454545rem 0.45454545rem;
  list-style: circle inside;
}
.news-list .news-announce ul:last-child,
.news-list .news-announce ol:last-child {
  margin-bottom: 0;
}
.news-list .news-announce ul:last-child li:last-child,
.news-list .news-announce ol:last-child li:last-child {
  margin-bottom: 0;
}
.news-list .news-announce:before,
.news-list .news-announce:after {
  content: " ";
  display: table;
}
.news-list .news-announce:after {
  clear: both;
}
.resources-list {
  margin: 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
}
.resources-list > li {
  margin: 0rem 0rem 2.27272727rem 0rem;
  list-style: none;
}
.resources-list > li.list-element-last {
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}
.resources-list > li p:last-child {
  padding: 0;
}
.resources-list > li ul:last-child,
.resources-list > li ol:last-child {
  margin-bottom: 0;
}
.resources-list > li ul:last-child li:last-child,
.resources-list > li ol:last-child li:last-child {
  margin-bottom: 0;
}
.resources-list > li {
  padding: 0rem 0rem 2.27272727rem 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  border-bottom: 1px solid #7D7D7D;
}
.resources-list > li.list-element-last {
  border: 0;
}
.resources-list .resources-title {
  margin-bottom: 0.22727273rem;
  font-weight: bold;
}
.resources-list .resources-announce {
  margin-bottom: 0.22727273rem;
  line-height: 120%;
}
.resources-list .resources-announce p {
  padding-bottom: 0.22727273rem;
  line-height: inherit;
}
.resources-list .resources-announce p:last-child {
  padding-bottom: 0;
}
.resources-list .resources-link a {
  padding-right: 0.45454545rem;
}
.resources-form-title {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 25px/120% Arial, Helvetica, sans-serif;
  font-size: 25px;
  line-height: 120%;
  text-transform: none;
  border-top: 1px solid #7D7D7D;
  padding-top: 0.90909091rem;
}
.resources-form-title a {
  text-decoration: none;
}
.resources-form-title,
.resources-form-title a {
  color: #000000;
}
.search-results-list {
  margin: 0rem 0rem 0rem 1.13636364rem;
  font-size: 22px;
  line-height: 120%;
}
.search-results-list .search-results-title {
  margin-bottom: 0.22727273rem;
  font-weight: bold;
}
.search-results-list .search-results-announce {
  margin-bottom: 0.22727273rem;
  line-height: 120%;
}
.search-results-list .search-results-announce p {
  padding-bottom: 0.22727273rem;
  line-height: inherit;
}
.search-results-list .search-results-announce p:last-child {
  padding-bottom: 0;
}
.search-results-list .search-results-link {
  margin: 0rem 0rem 2.27272727rem 0rem;
}
.sitemap-title {
  background: none;
  padding: 0rem;
  margin: 0rem 0rem 2.27272727rem 0rem;
  font: bold 24px/120% Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 120%;
  text-transform: none;
}
.sitemap-title a {
  text-decoration: none;
}
.sitemap-title,
.sitemap-title a {
  color: #000000;
}
.sitemap-list {
  margin: 0rem 0rem 2.27272727rem 0rem;
}
.sitemap-list > li {
  padding: 0rem;
  margin: 0rem 0rem 0.45454545rem 0.45454545rem;
  list-style: circle inside;
}
.sitemap-item {
  margin-bottom: 0.45454545rem;
  font-size: 22px;
  line-height: 120%;
}
.modal {
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  background: #FFFFFF;
  border: 0;
  box-shadow: none;
  border-radius: 0rem;
  height: auto;
  text-align: left;
  white-space: normal;
  z-index: 1001;
  width: 300px;
}
.modal .popup-header,
.modal .popup-body,
.modal .popup-footer {
  box-sizing: border-box;
  width: 100%;
}
.modal .popup-header {
  background: none;
  border: 0;
  border-radius: 0rem;
  height: 70px;
  padding: 0.90909091rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-align: center;
}
.modal .popup-body {
  background: none;
  border: 0;
  border-radius: 0rem;
  height: auto;
  padding: 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-align: left;
}
.modal .popup-footer {
  background: none;
  border: 0;
  border-radius: 0rem;
  height: 70px;
  padding: 0.90909091rem 0rem;
  font: 22px/120% Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 120%;
  color: #000000;
  text-align: center;
}
.modal .popup-close {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background: url(../images/button-close.png) no-repeat 0 0;
  width: 35px;
  height: 35px;
  padding: 0rem;
  font: 35px/100% Arial, Helvetica, sans-serif;
  font-size: 35px;
  line-height: 100%;
  color: #000000;
  text-decoration: none;
  vertical-align: top;
  cursor: pointer;
}
.black,
.black a {
  color: #000000;
}
.white,
.white a {
  color: #FFFFFF;
}
.red,
.red a {
  color: #950000;
}
.green,
.green a {
  color: #5E9010;
}
.main,
.main a {
  color: #000000;
}
.marker1 {
  background: url('../images/marker1.gif') no-repeat;
  background-position: 0rem 0.18181818rem;
  padding: 0rem 0rem 0rem 0.54545455rem;
}
.hr1 {
  background: #7D7D7D;
  height: 0.04545455rem;
  margin: 0.90909091rem 0rem;
}
.text-container {
  margin: 0rem 0rem 2.27272727rem 0rem;
  font-size: 22px;
  line-height: 120%;
}
.text-container p {
  line-height: inherit;
}
.text-container p:last-child {
  padding-bottom: 0;
}
.text-container ul {
  margin: 0rem 0rem 2.27272727rem 0rem;
}
.text-container ul > li {
  padding: 0rem;
  margin: 0rem 0rem 0.45454545rem 0.45454545rem;
  list-style: circle inside;
}
.text-container ul:last-child,
.text-container ol:last-child {
  margin-bottom: 0;
}
.text-container ul:last-child li:last-child,
.text-container ol:last-child li:last-child {
  margin-bottom: 0;
}
.s8 {
  font-size: 8px !important;
  line-height: 120% !important;
}
p.s8 {
  font-size: 8px !important;
  line-height: 120% !important;
}
.s9 {
  font-size: 9px !important;
  line-height: 120% !important;
}
p.s9 {
  font-size: 9px !important;
  line-height: 120% !important;
}
.s10 {
  font-size: 10px !important;
  line-height: 120% !important;
}
p.s10 {
  font-size: 10px !important;
  line-height: 120% !important;
}
.s11 {
  font-size: 11px !important;
  line-height: 120% !important;
}
p.s11 {
  font-size: 11px !important;
  line-height: 120% !important;
}
.s12 {
  font-size: 12px !important;
  line-height: 120% !important;
}
p.s12 {
  font-size: 12px !important;
  line-height: 120% !important;
}
.s13 {
  font-size: 13px !important;
  line-height: 120% !important;
}
p.s13 {
  font-size: 13px !important;
  line-height: 120% !important;
}
.s14 {
  font-size: 14px !important;
  line-height: 120% !important;
}
p.s14 {
  font-size: 14px !important;
  line-height: 120% !important;
}
.s15 {
  font-size: 15px !important;
  line-height: 120% !important;
}
p.s15 {
  font-size: 15px !important;
  line-height: 120% !important;
}
.s16 {
  font-size: 16px !important;
  line-height: 120% !important;
}
p.s16 {
  font-size: 16px !important;
  line-height: 120% !important;
}
.s17 {
  font-size: 17px !important;
  line-height: 120% !important;
}
p.s17 {
  font-size: 17px !important;
  line-height: 120% !important;
}
.s18 {
  font-size: 18px !important;
  line-height: 120% !important;
}
p.s18 {
  font-size: 18px !important;
  line-height: 120% !important;
}
.s19 {
  font-size: 19px !important;
  line-height: 120% !important;
}
p.s19 {
  font-size: 19px !important;
  line-height: 120% !important;
}
.s20 {
  font-size: 20px !important;
  line-height: 120% !important;
}
p.s20 {
  font-size: 20px !important;
  line-height: 120% !important;
}
.s21 {
  font-size: 21px !important;
  line-height: 120% !important;
}
p.s21 {
  font-size: 21px !important;
  line-height: 120% !important;
}
.s22 {
  font-size: 22px !important;
  line-height: 120% !important;
}
p.s22 {
  font-size: 22px !important;
  line-height: 120% !important;
}
.s23 {
  font-size: 23px !important;
  line-height: 120% !important;
}
p.s23 {
  font-size: 23px !important;
  line-height: 120% !important;
}
.s24 {
  font-size: 24px !important;
  line-height: 120% !important;
}
p.s24 {
  font-size: 24px !important;
  line-height: 120% !important;
}
.s25 {
  font-size: 25px !important;
  line-height: 120% !important;
}
p.s25 {
  font-size: 25px !important;
  line-height: 120% !important;
}
.s26 {
  font-size: 26px !important;
  line-height: 120% !important;
}
p.s26 {
  font-size: 26px !important;
  line-height: 120% !important;
}
.s27 {
  font-size: 27px !important;
  line-height: 120% !important;
}
p.s27 {
  font-size: 27px !important;
  line-height: 120% !important;
}
.s28 {
  font-size: 28px !important;
  line-height: 120% !important;
}
p.s28 {
  font-size: 28px !important;
  line-height: 120% !important;
}
.s29 {
  font-size: 29px !important;
  line-height: 120% !important;
}
p.s29 {
  font-size: 29px !important;
  line-height: 120% !important;
}
.s30 {
  font-size: 30px !important;
  line-height: 120% !important;
}
p.s30 {
  font-size: 30px !important;
  line-height: 120% !important;
}
.s31 {
  font-size: 31px !important;
  line-height: 120% !important;
}
p.s31 {
  font-size: 31px !important;
  line-height: 120% !important;
}
.s32 {
  font-size: 32px !important;
  line-height: 120% !important;
}
p.s32 {
  font-size: 32px !important;
  line-height: 120% !important;
}
.s33 {
  font-size: 33px !important;
  line-height: 120% !important;
}
p.s33 {
  font-size: 33px !important;
  line-height: 120% !important;
}
.s34 {
  font-size: 34px !important;
  line-height: 120% !important;
}
p.s34 {
  font-size: 34px !important;
  line-height: 120% !important;
}
.s35 {
  font-size: 35px !important;
  line-height: 120% !important;
}
p.s35 {
  font-size: 35px !important;
  line-height: 120% !important;
}
.s36 {
  font-size: 36px !important;
  line-height: 120% !important;
}
p.s36 {
  font-size: 36px !important;
  line-height: 120% !important;
}
.s37 {
  font-size: 37px !important;
  line-height: 120% !important;
}
p.s37 {
  font-size: 37px !important;
  line-height: 120% !important;
}
.s38 {
  font-size: 38px !important;
  line-height: 120% !important;
}
p.s38 {
  font-size: 38px !important;
  line-height: 120% !important;
}
.s39 {
  font-size: 39px !important;
  line-height: 120% !important;
}
p.s39 {
  font-size: 39px !important;
  line-height: 120% !important;
}
.s40 {
  font-size: 40px !important;
  line-height: 120% !important;
}
p.s40 {
  font-size: 40px !important;
  line-height: 120% !important;
}
.normal {
  font-weight: normal;
}
.bold {
  font-weight: bold;
}
.hover,
.hover a {
  text-decoration: none !important;
}
.hover:hover,
.hover a:hover {
  text-decoration: underline !important;
}
.unhover,
.unhover a {
  text-decoration: underline !important;
}
.unhover:hover,
.unhover a:hover {
  text-decoration: none !important;
}
.none,
.none a {
  border-bottom: 0 !important;
  text-decoration: none !important;
}
.underline,
.underline a {
  border-bottom: 1px solid;
  text-decoration: none !important;
  transition: border .3s;
}
.hover.underline,
.hover.underline a {
  border-bottom: 0;
}
.hover.underline:hover,
.hover.underline a:hover {
  border-bottom: 1px solid;
}
.unhover.underline:hover,
.unhover.underline a:hover {
  border-bottom: 0;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
.nobr {
  white-space: nowrap;
}
.visuallyhidden {
  position: absolute;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  white-space: nowrap;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  position: static;
  overflow: visible;
  clip: auto;
  clip-path: none;
  width: auto;
  height: auto;
  margin: 0;
  white-space: inherit;
}
.image-responsive {
  box-sizing: border-box;
  display: block;
  max-width: 100%;
  height: auto;
}
.image-bordered {
  border: 0.04545455rem solid #7D7D7D;
  padding: 0.09090909rem;
}
.image-circle {
  border-radius: 50%;
}
.embed-responsive {
  display: block;
  overflow: hidden;
  position: relative;
  height: 0;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}
.embed-responsive.embed-responsive-4by3 {
  padding-bottom: 75%;
}
.row-2 {
  box-sizing: border-box;
  margin: 0rem -0.45454545rem;
}
.row-2:before,
.row-2:after {
  content: " ";
  display: table;
}
.row-2:after {
  clear: both;
}
.row-2 .col {
  box-sizing: border-box;
  position: relative;
  float: left;
  min-height: 1px;
  width: 50%;
  padding: 0rem 0.45454545rem;
}
.row-3 {
  box-sizing: border-box;
  margin: 0rem -0.45454545rem;
}
.row-3:before,
.row-3:after {
  content: " ";
  display: table;
}
.row-3:after {
  clear: both;
}
.row-3 .col {
  box-sizing: border-box;
  position: relative;
  float: left;
  min-height: 1px;
  width: 33.33333333%;
  padding: 0rem 0.45454545rem;
}
.table-responsive {
  overflow: hidden;
  overflow-x: auto;
}
.table-responsive:before,
.table-responsive:after {
  content: " ";
  display: table;
}
.table-responsive:after {
  clear: both;
}
.table-responsive table {
  margin-bottom: 0;
}
.nowrap {
  white-space: nowrap !important;
}
.valign-top {
  vertical-align: top !important;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}
.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.hidden {
  display: none !important;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.center-block-content {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.center-block-content > div {
  display: block;
  position: relative;
  float: left;
  left: 50%;
}
.center-block-content > div > div {
  display: block;
  position: relative;
  float: left;
  right: 50%;
}
.justify-block-content {
  line-height: 0;
  letter-spacing: -1px;
  text-align: justify;
}
.justify-block-content:after {
  display: inline-block;
  visibility: hidden;
  width: 100%;
  content: ".";
}
.justify-block-content > * {
  display: inline-block;
  width: auto;
  line-height: 120%;
  letter-spacing: normal;
  vertical-align: top;
}
.left {
  float: left;
}
.right {
  float: right;
}
.pull-left {
  float: left;
  margin: 0rem 0.68181818rem 0.45454545rem 0rem;
}
table.pull-left,
th.pull-left,
td.pull-left {
  float: none;
  margin: 0;
  text-align: left !important;
}
.pull-right {
  float: right;
  margin: 0rem 0rem 0.45454545rem 0.68181818rem;
}
table.pull-right,
th.pull-right,
td.pull-right {
  float: none;
  margin: 0;
  text-align: right !important;
}
.primary-list {
  margin: 0rem 0rem 2.27272727rem 0rem;
}
.primary-list > li {
  padding: 0rem;
  margin: 0rem 0rem 0.45454545rem 0.45454545rem;
  list-style: circle inside;
}
.m0 {
  margin-bottom: 0rem !important;
}
.m1 {
  margin-bottom: 0.04545455rem !important;
}
.m2 {
  margin-bottom: 0.09090909rem !important;
}
.m3 {
  margin-bottom: 0.13636364rem !important;
}
.m4 {
  margin-bottom: 0.18181818rem !important;
}
.m5 {
  margin-bottom: 0.22727273rem !important;
}
.m6 {
  margin-bottom: 0.27272727rem !important;
}
.m7 {
  margin-bottom: 0.31818182rem !important;
}
.m8 {
  margin-bottom: 0.36363636rem !important;
}
.m9 {
  margin-bottom: 0.40909091rem !important;
}
.m10 {
  margin-bottom: 0.45454545rem !important;
}
.m15 {
  margin-bottom: 0.68181818rem !important;
}
.m20 {
  margin-bottom: 0.90909091rem !important;
}
.m25 {
  margin-bottom: 1.13636364rem !important;
}
.m30 {
  margin-bottom: 1.36363636rem !important;
}
.m35 {
  margin-bottom: 1.59090909rem !important;
}
.m40 {
  margin-bottom: 1.81818182rem !important;
}
.m45 {
  margin-bottom: 2.04545455rem !important;
}
.m50 {
  margin-bottom: 2.27272727rem !important;
}
.m55 {
  margin-bottom: 2.5rem !important;
}
.m60 {
  margin-bottom: 2.72727273rem !important;
}
.m65 {
  margin-bottom: 2.95454545rem !important;
}
.m70 {
  margin-bottom: 3.18181818rem !important;
}
.p0 {
  padding-bottom: 0rem !important;
}
.p1 {
  padding-bottom: 0.04545455rem !important;
}
.p2 {
  padding-bottom: 0.09090909rem !important;
}
.p3 {
  padding-bottom: 0.13636364rem !important;
}
.p4 {
  padding-bottom: 0.18181818rem !important;
}
.p5 {
  padding-bottom: 0.22727273rem !important;
}
.p6 {
  padding-bottom: 0.27272727rem !important;
}
.p7 {
  padding-bottom: 0.31818182rem !important;
}
.p8 {
  padding-bottom: 0.36363636rem !important;
}
.p9 {
  padding-bottom: 0.40909091rem !important;
}
.p10 {
  padding-bottom: 0.45454545rem !important;
}
.p15 {
  padding-bottom: 0.68181818rem !important;
}
.p20 {
  padding-bottom: 0.90909091rem !important;
}
.p25 {
  padding-bottom: 1.13636364rem !important;
}
.p30 {
  padding-bottom: 1.36363636rem !important;
}
.p35 {
  padding-bottom: 1.59090909rem !important;
}
.p40 {
  padding-bottom: 1.81818182rem !important;
}
.p45 {
  padding-bottom: 2.04545455rem !important;
}
.p50 {
  padding-bottom: 2.27272727rem !important;
}
.p55 {
  padding-bottom: 2.5rem !important;
}
.p60 {
  padding-bottom: 2.72727273rem !important;
}
.p65 {
  padding-bottom: 2.95454545rem !important;
}
.p70 {
  padding-bottom: 3.18181818rem !important;
}
.clearfix:before,
.nofloat:before,
.text-container:before,
.clearfix:after,
.nofloat:after,
.text-container:after {
  content: " ";
  display: table;
}
.clearfix:after,
.nofloat:after,
.text-container:after {
  clear: both;
}
.clear {
  clear: both;
}
.float-none {
  overflow: hidden;
}
@media only all and (max-width: 767px) {
  main {
    float: none;
    width: 100% !important;
  }
}
@media only all and (max-width: 953px) {
  .wrapper {
    width: 100%;
    min-width: 320px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only all and (max-width: 299px) {
  .modal {
    border-radius: 0;
    width: 100% !important;
    margin: 0;
  }
}
@media only all and (max-width: 767px) {
  .form .form-title {
    float: none;
    width: 100%;
    padding: 0rem 0rem 2.27272727rem 0rem;
  }
  .form .form-field {
    float: none;
    width: 100%;
  }
}
@media only all and (max-width: 767px) {
  .row-2,
  .row-3 {
    margin: 0 !important;
  }
  .row-2 .col,
  .row-3 .col {
    float: none;
    width: 100%;
    padding: 0rem;
  }
}
@media only all and (max-width: 767px) {
  .hidden-768 {
    display: none !important;
  }
  .visible-768 {
    display: block !important;
  }
  span.visible-768 {
    display: inline-block !important;
  }
  th.visible-768,
  td.visible-768 {
    display: table-cell !important;
  }
}
@media only all and (max-width: 599px) {
  .hidden-600 {
    display: none !important;
  }
  .visible-600 {
    display: block !important;
  }
  span.visible-600 {
    display: inline-block !important;
  }
  th.visible-600,
  td.visible-600 {
    display: table-cell !important;
  }
}
.mobile-menu-link {
  position: absolute;
  top: 50%;
  left: -10px;
  transform: translate(0, -50%);
  width: 30px;
  height: 30px;
}
.mobile-menu-link a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
}
.mobile-menu-link .bars {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mobile-menu-link .bars,
.mobile-menu-link .bars:after,
.mobile-menu-link .bars:before {
  display: block;
  position: absolute;
  background: #000000;
  width: 15px;
  height: 2px;
}
.mobile-menu-link .bars:after,
.mobile-menu-link .bars:before {
  position: absolute;
  left: 0;
  content: "";
}
.mobile-menu-link .bars:before {
  top: 4px;
}
.mobile-menu-link .bars:after {
  bottom: 4px;
}
.isDesktop .only-touch {
  display: none !important;
}
.isTouchscreen .only-desktop {
  display: none !important;
}
@media only all and (min-width: 768px) {
  .only-mobile {
    display: none !important;
  }
}
@media only all and (max-width: 767px) {
  .isDesktop .only-mobile {
    display: none !important;
  }
}
@media only all and (max-width: 953px) {
  html {
    font-size: 22px;
  }
  .block .abs {
    position: static !important;
    width: 100% !important;
    height: auto !important;
    margin-bottom: 0.90909091rem !important;
  }
  .block .image a {
    cursor: default;
  }
  .block .image img {
    box-sizing: border-box;
    display: block;
    max-width: 100%;
    height: auto;
  }
  .hidden-base-width {
    display: none !important;
  }
}
@media only all and (max-width: 767px) {
  h1,
  .h1,
  nav li a {
    font-size: 28px;
    line-height: 120%;
  }
}
@media only all and (max-width: 600px) {
  nav li:last-child {
    display: block;
    float: none;
  }
}
@media only all and (max-width: 414px) {
  .block .image img {
    width: 100%;
  }
}
