// Print styles
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    box-shadow: none !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: @black !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  blockquote,
  img,
  pre,
  tr {
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p {
    orphans: 3;
    widows: 3;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    page-break-after: avoid;
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 1px solid #999999;
  }

  .input,
  select,
  textarea {
    border-color: @black;
  }

  aside,
  footer,
  header,
  nav,
  #awst-panel-wrapper,
  .breadcrumbs,
  .pagination,
  .noprint {
    display: none !important;
  }
  main {
    float: none !important;
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}