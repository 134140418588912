// Site stylesheets

// Imports
// @import '../jquery.inputs/jquery.inputs.less';
@import 'colorbox/colorbox.min.css';
@import '//katyasivers.com/core/view/ui/css/hot-sneaks/jquery-ui.css';

// Local variables

// Local mixins
.fixer() {
  position: fixed;
  width: 100%;
}

// Customized global styles
#content {
  .fixer & {
    .fixer;
  }
}

// Header
header,
#header-fake {
  .set-value(padding; 5 0 50 0);

  .text-container {
    margin: 0;
  }
}
header {
  .fixer & {
    .fixer;
    top: 0;
  }
}
#header-fake {
  visibility: hidden;
}

// Main navigation
nav {
  .set-value(padding; @paragraph-padding);

  &.fixed {
    .fixer;
    top: 0;
    z-index: 1000;
  }

  li {
    list-style: none;

    &:not(:first-child) {
      display: inline-block;
    }
    &:last-of-type:not(:only-of-type) {
      float: right;
    }

    a {
      border-bottom: 1px solid transparent;
      .font-size(@h1-fs);

      &:not(.no-separator):after {
        content: ',';
      }
      &:hover {
        border-color: @text-color;
      }
    }
  }
}

// Footer
footer {
}


// Blocks
#projects {
  .set-value(margin-top; 10);
}
#projects,
#ks-drag-container {
  .text-container {
    &,
    h1,
    .h1 {
      margin: 0;
    }
  }
}
#projects,
#publications,
#media,
#contacts,
#ks-drag-container,
.error-page {
  .text-container {
    p {
      .padding-bottom(20);

      &:last-child {
        padding: 0;
      }
    }
    a {
      border-bottom: 1px solid;

      &:hover {
        border-color: transparent;
      }
    }
  }
}
#publications,
#media,
#contacts,
.error-page {
  .text-container {
    .font-size(16);
  }
}

.block {
  position: relative;
  .set-value(margin; 20 0 @paragraph-padding-one 0);

  .abs {
    position: absolute;
  }
  .isDesktop & {
    .fly,
    .fly * {
      cursor: move;
    }
  }
}
.curators-text {
  background: @white;
  .set-value(padding; 35 25 15 35);
}
.more {
  &-handler { //. more-handler
    display: inline-block;
    border-bottom-style: dotted !important;
  }
  &-content { // .more-content
    display: none;
    .set-value(margin-top; 5);
    .font-size(16);
  }
}


.footer-counters {
  float: left;
  .set-value(padding; 0 90 0 20);

  img {
    float: left;
    .set-value(margin-right; 10);
  }
}
.footer-copyright {
  a {
  }
}


.articles-list {
  .list-hr;

  .articles-title {
    .h4;
  }
}

.contacts-title {
  .margin-bottom(5);
  .font-size(@font-size-base; @paragraph-line-height);
  .bold;
}
.contacts-value {
  .set-value(padding-left; 10);
  .font-size(@font-size-base);

  &.contacts-several-values {
    > * {
      &:not(p) {
        .set-value(padding; @paragraph-padding);
      }
      &:not(:last-child) {
        .set-value(padding-bottom; 5);
      }
    }
  }
}
.contacts-map {
  .pull-right;
  .size(500; 400);

  > * {
    display: block;
    box-sizing: border-box;
    .image-bordered;

    &,
    & img {
      .square(100%) !important;
    }
  }
}

.galleries-list {
  .list-hr;

  .galleries-title-container {
    .clearfix;

    .galleries-title {
      .h4;
    }
    .galleries-all {
      .pull-right;
    }
  }
}
.galleries-foto {
  .clearfix;
  .set-value(margin; 0 0 -8 -8);

  .galleries-foto-item {
    float: left;
    .set-value(margin; 0 0 8 8);

    img {
      .image-bordered;
    }
  }
}

.info-list {
  .list-hr;
  border-top: 1px solid @hr-color-base;
  .set-value(padding-top; 20);

  .info-title {
    .h4;
  }
  .info-announce {
    .text-container;
  }
}

.news-date {
  .margin-bottom(10);
  color: @black-50;
}
.news-list {
  .list-hr;

  .news-title {
    .h4;
  }
  .news-announce {
    .text-container;
  }
}

.resources-list {
  .list-hr;

  .resources-title {
    .margin-bottom(5);
    .bold;
  }
  .resources-announce {
    .set-value(margin-bottom; 5);
    line-height: @paragraph-line-height;

    p {
      .padding-bottom(5);
      line-height: inherit;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  .resources-link {
    a {
      .set-value(padding-right; 10);
    }
  }
}
.resources-form-title {
  .h3;
  border-top: 1px solid @hr-color-base;
  .set-value(padding-top; 20);
}

.search-results-list {
  .set-value(margin; 0 0 0 25);
  .font-size(@font-size-base);

  .search-results-title {
    .margin-bottom(5);
    .bold;
  }
  .search-results-announce {
    .set-value(margin-bottom; 5);
    line-height: @paragraph-line-height;

    p {
      .padding-bottom(5);
      line-height: inherit;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  .search-results-link {
    .set-value(margin; @paragraph-padding);
  }
}

.sitemap-title {
  .h4;
}
.sitemap-list {
  .primary-list;
}
.sitemap-item {
  .margin-bottom(10);
  .font-size(@font-size-base);
}


// Tables

// Forms

// Popups and modal windows
.modal {
  .popup;
  z-index: @modal-zIndex;
  width: @modal-width;
}

// Colors
.black,
.black a {
  color: @black;
}
.white,
.white a {
  color: @white;
}
.red,
.red a {
  color: @red;
}
.green,
.green a {
  color: @green;
}
.main,
.main a {
  color: @text-color;
}

// Markers
.marker1 {
  .marker('../images/marker1.gif'; 0 0 0 12; 0 4px);
}

// Horizontal lines
.hr1 {
  .hr(@hr-color-base; 20 0);
}