// HTML5 structure
header {
  box-sizing: border-box;
  background: @header-background;
  .set-value(height; @header-height; padding; @header-padding);

  > .wrapper when not (@header-wrapper-background = none) {
    background: @header-wrapper-background;
  }
}
#structure {
  position: relative;
  background: @structure-background;
  margin: 0 auto;

  & when (@move-max-width-to-structure) {
    max-width: @max-width-base;
  }
  & when (@footer-to-bottom) {
    min-height: 100%;
  }
}
#content {
  background: @content-background;

  & when (@footer-to-bottom) and not (@footer-height = auto) {
    .set-value_(padding-bottom; (@footer-height + @content-padding-bottom));
  }
  & when not (@footer-to-bottom) {
    .set-value_(padding-bottom; @content-padding-bottom);
  }

  .wrapper when not (@content-wrapper-background = none) {
    background: @content-wrapper-background;
  }
  .wrapper {
    .clearfix;
  }
}
aside,
main {
  box-sizing: border-box;
  float: left;
}
aside#left {
  background: @sidebar-left-background;
  .set-value(width; @sidebar-left-width; padding; @sidebar-left-padding);
}
aside#right {
  background: @sidebar-right-background;
  .set-value(width; @sidebar-right-width; padding; @sidebar-right-padding);
}
main {
  background: @main-background;
  .set-value(width; calc(100% - @sidebar-left-width - @sidebar-right-width); padding; @main-padding);

  &.no-aside-left {
    width: calc(100% - @sidebar-right-width);
    padding-left: 0;
  }
  &.no-aside-right {
    width: calc(100% - @sidebar-left-width);
    padding-right: 0;
  }
  &.full,
  &.no-aside-left.no-aside-right {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
footer {
  position: relative;
  box-sizing: border-box;
  background: @footer-background;
  .set-value(padding; @footer-padding; margin; 0 auto);
  text-align: @footer-text-align;

  & when (@apply-max-width-to-footer) {
    max-width: @max-width-base;
  }

  > .wrapper when not (@footer-wrapper-background = none) {
    background: @footer-wrapper-background;
  }
  > .wrapper when not (@footer-height = auto) {
    & when (@footer-to-bottom) {
      .set-value(height; @footer-height; margin-top; (@footer-height * -1));
    }
    & when not (@footer-to-bottom) {
      .set-value_(min-height; @footer-height);
    }
  }
}

.wrapper {
  box-sizing: border-box;
  .set-value(padding; @wrapper-padding; margin; 0 auto);

  & when (@wrapper-fixed) and not (@move-max-width-to-structure) {
    .set-value_(width; @max-width-base);
  }
  & when (@wrapper-fixed) and (@move-max-width-to-structure) {
    .set-value_(width; @min-width-base);
  }
  & when not (@wrapper-fixed) {
    min-width: @min-width-base;
    max-width: @max-width-base;
  }
}